import { Component, OnInit } from '@angular/core';
import { AutoLogoutService } from '../auto-logout.service';

@Component({
  selector: 'app-autoredirect',
  templateUrl: './autoredirect.component.html',
  styleUrls: ['./autoredirect.component.scss'],
  providers: [AutoLogoutService]
})
export class AutoredirectComponent implements OnInit {

  constructor(private autoLogoutService: AutoLogoutService) { }

  ngOnInit() {
  }

}