import {trigger, state, stagger, sequence, animate, style, group, query as q, transition, keyframes} from '@angular/animations';


const query = (s:any,a:any,o:any={optional:true})=>q(s,a,o);

export const homeTransition = trigger('homeTransition', [
  state('*', style({transform: 'translateX(0)'}) ),
  transition(':enter', [
    query('.block', style({ opacity: 0 })),
    query('.block', stagger(150, [
      style({ transform: 'translateX(100%)' }),
      animate('.6s cubic-bezier(.75,-0.48,.26,1.52)', style({transform: 'translateX(0)', opacity: 1})),
    ])),
    
  ]),
  transition(':leave', [
    query('.block', stagger(150, [
      style({ transform: 'translateX(0)', opacity: 1 }),
      animate('.6s cubic-bezier(.75,-0.48,.26,1.52)', style({transform: 'translateX(100%)', opacity: 0})),
    ])), 
       
  ]),
  transition('* => void', [
    query('.block', stagger(150, [
      style({ transform: 'translateX(0)', opacity: 1 }),
      animate('.6s cubic-bezier(.75,-0.48,.26,1.52)', style({transform: 'translateX(100%)', opacity: 0})),
    ])),
  ])
]);
export const masoryTransition = trigger('masoryTransition', [
  state('in', style({transform: 'translateX(0)', opacity: 1})),
    transition('void => *', [
      animate('.3s cubic-bezier(.75,-0.48,.26,1.52)', keyframes([
        style({opacity: 0, transform: 'translateX(100%)'}),
        style({opacity: 1, transform: 'translateX(0)'})
      ]))
    ]),
    transition('* => void', [
      animate('.3s cubic-bezier(.75,-0.48,.26,1.52)', keyframes([
        style({opacity: 1, transform: 'translateX(0)'}),
        style({opacity: 0, transform: 'translateX(100%)'})
      ]))
    ])
]);

