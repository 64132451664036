import { Component, OnInit } from '@angular/core';
import {Globals} from '../globals'
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public lang:any;
    constructor(private globals: Globals) { 
        this.lang = globals.lang
    }

    ngOnInit(): void {
    }

}
