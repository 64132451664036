import { ViewChild, ViewChildren, Component, OnInit, Renderer2, OnDestroy, AfterViewInit, ElementRef} from '@angular/core';
import { masoryTransition } from '../router.animations';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {Router, ActivatedRoute} from "@angular/router";
import {Globals} from '../globals'
import { Location } from '@angular/common';
import {Observable} from 'rxjs';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { faTrash, faCamera } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
  animations: [ masoryTransition ],
  host: {
    '[@masoryTransition]': ''
  }
})

export class CameraComponent implements OnInit {
    @ViewChild('hardwareVideo') private hardwareVideo: any;
    @ViewChild('HTMLImageElement') private HTMLImageElement: any;
    @ViewChild('HTMLCanvasElement') private HTMLCanvasElement: any;
    @ViewChild('trash') private trash: any;
    @ViewChild('counter') private counter: any;
    @ViewChild('scatta') private scatta: any;
    @ViewChild('next') private next: any;
    @ViewChild('loading') private loading: any;
    public basePath: string;
    public baseServer: string;
    public camera: any;
    public mini: any;
    faTrash = faTrash;
    faCamera = faCamera;
    public video: any;
    public count:any;
    public seconds: number = 5;
    public diff: number = 5;
    public downloadTimer:any;
    public lang:any;
    public localStream:any
    public stream:any;
    constructor(private globals: Globals, private location: Location, private renderer:Renderer2, private http: HttpClient, private router: Router, private ruta:ActivatedRoute) {
        this.basePath = globals.base
        this.baseServer = globals.baseServer
        this.lang = globals.lang
        this.camera = globals.camera
        this.mini = globals.mini
    }

    ngOnInit() {
        setTimeout(()=>{
            this.videoStart();
            console.log('videostart')
        }, 0)
        if(localStorage.foto) {
            localStorage.removeItem('foto');
        }else{
            this.router.navigate(['../camera'], { relativeTo: this.ruta });
        }
    }
 
    videoStart(){
        let video = this.hardwareVideo.nativeElement;
        let n = <any>navigator;
        let constraints = {
            audio: false,
            video: {
                width: { min: 1024, ideal: 1280, max: 1920 },
                height: { min: 576, ideal: 720, max: 1080 }
            }
        }
        n.getUserMedia = ( n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia  || n.msGetUserMedia );
        n.mediaDevices.getUserMedia(constraints).then(this.successCallback.bind(this))
    }

    successCallback(stream: MediaStream) {
        let video = this.hardwareVideo.nativeElement;
        this.stream = stream;
        video.srcObject = stream;
        video.play();
    }
  
    capture(){
        let video = this.hardwareVideo.nativeElement;
        let img = this.HTMLImageElement.nativeElement;
        let canvas = this.HTMLCanvasElement.nativeElement;
        let scatta = this.scatta.nativeElement;
        let n = <any>navigator;
        let constraints = {
            audio: false,
            video: {
                width: { min: 1024, ideal: 1280, max: 1920 },
                height: { min: 576, ideal: 720, max: 1080 }
            }
        }
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        n.getUserMedia = ( n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia  || n.msGetUserMedia );

        n.mediaDevices.getUserMedia(constraints).then(function(stream:any) {    
            //console.log('callbackStream')
            (<any>window).srcObject = stream;
            let context = canvas.getContext('2d');
            setTimeout(()=>{ 
                context.drawImage(video, 0,0,video.videoWidth, video.videoHeight)
                var data = canvas.toDataURL('image/jpeg',1.0);
                img.setAttribute('src', data);
                
            },600);
        });
        this.renderer.addClass(video, 'none')
        this.renderer.addClass(scatta, 'none')
        let trash = this.trash.nativeElement;
        this.renderer.removeClass(trash, 'none')
        this.renderer.removeClass(img, 'none')
        this.renderer.removeClass(canvas, 'none')
    }

    trashFoto(){
        let video = this.hardwareVideo.nativeElement;
        let img = this.HTMLImageElement.nativeElement;
        let canvas = this.HTMLCanvasElement.nativeElement;
        let trash = this.trash.nativeElement;
        let scatta = this.scatta.nativeElement
        let counter = this.counter.nativeElement;
        let next = this.next.nativeElement;
        this.seconds = 5;
        this.diff = 5;
        this.renderer.addClass(trash, 'none')
        this.renderer.addClass(canvas, 'none')
        this.renderer.addClass(img, 'none')
        this.renderer.removeClass(video, 'none')
        this.renderer.addClass(counter, 'none')
        this.renderer.removeClass(scatta, 'none')
        this.renderer.addClass(next, 'none')
        canvas.getContext('2d').drawImage(video, 0,0,video.videoWidth, video.videoHeight)
    }

    countdown(){
        let counter = this.counter.nativeElement
        let scatta = this.scatta.nativeElement
        let next  = this.next.nativeElement
        this.renderer.addClass(scatta, 'none')
        this.renderer.removeClass(counter, 'none');
        this.diff = 5;
        this.downloadTimer = interval(1000).pipe(map((x) => {
            this.diff-=1;
        })).subscribe((x) => {       
            this.seconds = this.diff;
            if(this.seconds <= 0){
                this.downloadTimer.unsubscribe()
                clearInterval(this.downloadTimer)
                this.renderer.addClass(counter, 'none')
                this.renderer.removeClass(next, 'none')
                this.capture();
            }
        });
    }

    nextStep(){
        let canvas = this.HTMLCanvasElement.nativeElement;
        let scatta = this.scatta.nativeElement
        let next = this.next.nativeElement;
        this.renderer.addClass(next, 'none');
        this.renderer.addClass(scatta, 'none');
        let loading = this.loading.nativeElement;
        this.renderer.removeClass(loading, 'none')
        this.renderer.addClass(next, 'none')
        let rFltr = /^(?:image\/bmp|image\/cis\-cod|image\/gif|image\/ief|image\/jpeg|image\/jpeg|image\/jpeg|image\/pipeg|image\/png|image\/svg\+xml|image\/tiff|image\/x\-cmu\-raster|image\/x\-cmx|image\/x\-icon|image\/x\-portable\-anymap|image\/x\-portable\-bitmap|image\/x\-portable\-graymap|image\/x\-portable\-pixmap|image\/x\-rgb|image\/x\-xbitmap|image\/x\-xpixmap|image\/x\-xwindowdump)$/i
        let base = canvas.toDataURL(rFltr).replace(/^data:image\/(png|jpg);base64,/, "")
        
        let obj = <any>{}
        obj['my_img'] = base
        let arr = []
        arr.push(obj)

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                //'Authorization': 'my-auth-token'
            })
        };
        interface risposte {
        name: string;
        }
        console.log(this.baseServer+'image.php')
        this.http.post<risposte>(this.baseServer+'image.php', JSON.stringify(arr), httpOptions)
        .subscribe((risposte) => {
            localStorage.setItem('foto', risposte.name)
            this.stopCamera()
        },
        response => {
            console.log("DELETE call in error", response);
            this.stopCamera()
            setTimeout(()=>{ 
                this.router.navigate(['/camera']);
            }, 300)
        },
        () => {
            this.renderer.addClass(loading, 'none')
            this.stopCamera()
            setTimeout(()=>{ 
                this.router.navigate(['../messaggio'], { relativeTo: this.ruta });
            }, 300)
        });
        
    }
    stopCamera() {
        let stream = this.stream;
        console.log('stop')
        stream.getAudioTracks().forEach((track:any) => track.stop());
        stream.getVideoTracks().forEach((track:any) => track.stop());
    }
    goBack() {
        this.stopCamera()
        setTimeout(()=>{ 
        this.location.back();
        }, 300)
    }
    home() {
        this.stopCamera()
        setTimeout(()=>{ 
        this.router.navigate(['../'], { relativeTo: this.ruta });
        }, 300)
    }

}