import { Component, OnInit } from '@angular/core';
import { homeTransition} from '../router.animations';
import { masoryTransition } from '../router.animations';
import {Globals} from '../globals'
import {Router, ActivatedRoute} from "@angular/router";

@Component({

  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
  animations: [ masoryTransition ],
  host: {
    '[@masoryTransition]': ''
  },
  
})
export class StartComponent implements OnInit {
  lang:any
  start:any
  constructor(private globals: Globals, private router: Router, private ruta:ActivatedRoute) { 
    this.lang = globals.lang
    this.start = globals.start
  }

  ngOnInit() {
    
  }

  changeLang(lang:any){
    this.router.navigate(['/'+lang])
    window.location.reload(true);
  }
  

}
