import {sequence, trigger, stagger, animate, style, group, query as q, transition, keyframes, animateChild} from '@angular/animations';
const query = (s:any,a:any,o:any={optional:true})=>q(s,a,o);

export const routerTransition = trigger('routerTransition', [
  transition('* => *', [
    query(':enter, :leave', style({ position: 'fixed', width:'1920px', height:'1080px', overflow:'hidden' })),
    query(':enter', style({  })),
    sequence([
      query(':leave', animateChild()), 
      group([
        query(':leave', [
          style({ opacity: 0 }),
          animate('250ms cubic-bezier(.75,-0.48,.26,1.52)', 
            style({  }))
        ]),
        query(':enter', [
          style({  }),
          animate('250ms cubic-bezier(.75,-0.48,.26,1.52)', 
            style({  })),
        ]),
      ]),
      query(':enter', animateChild()),
    ])
  ])
]);