import { ViewChild, Component, OnInit, ViewEncapsulation } from '@angular/core';
import Keyboard from "simple-keyboard";
import { masoryTransition } from '../router.animations';
import {Globals} from '../globals'
import {Router, ActivatedRoute} from "@angular/router";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClientModule, HttpHeaders, HttpClient } from '@angular/common/http';
import {NG_VALIDATORS} from '@angular/forms';
@Component({
  selector: 'app-messaggio',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './messaggio.component.html',
  styleUrls: ['./messaggio.component.scss'],
  animations: [ masoryTransition ],
  host: {
    '[@masoryTransition]': ''
  }
})

export class MessaggioComponent implements OnInit {
    @ViewChild('next') private next: any;
    private basePath: string;
    private baseServer: string;
    private italian:any;
    myform:any = FormGroup;
    nome:any = FormControl;
    email:any = FormControl;
    messaggio:any = FormControl;
    privacy:any = FormControl;
    imagePeople:any;
    public messaggiolang:any
    public mesage:any
    public status: boolean = false;
    value = "";
    keyboard:any = Keyboard;
    lang:any
    selectedInputElem: any;
    message:any
    
  constructor(private globals: Globals, private router: Router, private ruta:ActivatedRoute, private http: HttpClient) {
    this.basePath = globals.base
    this.baseServer = globals.baseServer
    this.lang = globals.lang
    this.messaggiolang = globals.messaggio
    console.log(this.messaggiolang)
    this.italian = {
      default: [
        "\\ 1 2 3 4 5 6 7 8 9 0 ' \u00EC {bksp}",
        "{tab} q w e r t y u i o p \u00E8 +",
        "{lock} a s d f g h j k l \u00F2 \u00E0 \u00F9 {enter}",
        "{shift} < z x c v b n m , . - {shift}",
        ".com @ {space}"
      ],
      shift: [
        '| ! " \u00A3 $ % & / ( ) = ? ^ {bksp}',
        "{tab} Q W E R T Y U I O P \u00E9 *",
        "{lock} A S D F G H J K L \u00E7 \u00B0 \u00A7 {enter}",
        "{shift} > Z X C V B N M ; : _ {shift}",
        ".com @ {space}"
      ]
    }
   }

  
  ngAfterViewInit() {
    this.keyboard = new Keyboard({
      debug: true,
      onChange: input => this.onChange(input),
      onKeyPress: button => this.onKeyPress(button),
      preventMouseDownDefault: true,
      layout:this.italian,
      theme: "hg-theme-default myTheme1"
    });

    this.selectedInputElem = document.querySelector(".input:first-child");

    document.querySelectorAll(".input").forEach(input => {
     
        input.addEventListener("focus", this.onInputFocus);
        input.addEventListener("input", this.onInputChange);
      
    });
   
  }

  onInputFocus = (event: any) => {
    this.selectedInputElem = event.target;

    this.keyboard.setOptions({
      inputName: event.target.id
    });
  };

  setInputCaretPosition = (elem: any, pos: number) => {
      
    if (elem.setSelectionRange) {
      elem.focus();
      elem.setSelectionRange(pos, pos);
    }
  };

  onInputChange = (event: any) => {
    this.keyboard.setInput(event.target.value, event.target.id);
  };

  onChange = (input: string) => {
    let control = this.selectedInputElem.getAttribute('formcontrolname')
    this.myform.controls[control].setValue(input)

    this.selectedInputElem.value = input;
    let caretPosition = this.keyboard.caretPosition;
    if (caretPosition !== null)
      this.setInputCaretPosition(this.selectedInputElem, caretPosition);
  };

  onKeyPress = (button: string) => {
    if (button === "{shift}" || button === "{lock}") this.handleShift();
  };

  handleShift = () => {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";

    this.keyboard.setOptions({
      layoutName: shiftToggle
    });
  };
  
  openDialog():void{
    this.status = !this.status;
  }

  ngOnInit(): void {
    this.createFormControls();
    this.createForm();
    this.imagePeople = this.baseServer+'fotografie/'+localStorage.getItem('foto')
  }

  onSubmit(values:any) {
    console.log(this.myform.value)
    let lang = this.lang
    let next = this.next.nativeElement;
    console.log(this.myform.valid)
    if (this.myform.valid) {
      let obj:any = {}
      obj['email']=this.email.value
      obj['nome']=this.nome.value
      obj['messaggio']=this.messaggio.value
      obj['img']=this.imagePeople
      let arr = new Array()
      arr.push(obj)
      interface risposte {
        message: string;
      }
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          //'Authorization': 'my-auth-token'
        })
      };
      this.http.post<risposte>(this.baseServer+'send.php', JSON.stringify(arr), httpOptions)
      .subscribe((risposte:any) => {
        //console.log(risposte.message)
        localStorage.setItem('foto', risposte.name)
        },
        response => {
          //this.renderer.addClass(loading, 'none')
          //this.renderer.removeClass(next, 'none')
            console.log("DELETE call in error", response);
            this.router.navigate(['/camera']);
        },
        () => {
            this.status = true
          //this.renderer.removeClass(next, 'none')
          //this.renderer.addClass(loading, 'none')
          this.message = this.messaggio.value
          if(lang == 'it'){
            
            this.mesage = 'CARTOLINA INVIATA CON SUCCESSO'
          }else{
            this.mesage = 'POSTCARD SENT WITH SUCCESS'
          }
          //this.router.navigate(['../messaggio'], { relativeTo: this.ruta });
          this.myform.reset();
        });
      //console.log("Form Submitted!");
      //this.myform.reset();*/
    }else{
      //console.log("Form Non Validated!");
      //this.renderer.addClass(loading, 'none')
      //this.renderer.removeClass(next, 'none')
      this.validateAllFormFields(this.myform);
    }
  }
  validateAllFormFields(formGroup: FormGroup) {         
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);             
      if (control instanceof FormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        
        this.validateAllFormFields(control);            
      }
    });
  }
    createFormControls() {
        this.nome = new FormControl('', Validators.required);
      
        this.email = new FormControl('', [Validators.required, Validators.pattern("[^ @]*@[^ @]*")]);
      
        this.messaggio = new FormControl('', Validators.required);
        this.privacy = new FormControl('', Validators.requiredTrue);
      
    }

   
    createForm() {
        this.myform = new FormGroup({
            nome: this.nome,
            email: this.email,
            messaggio: this.messaggio,
            privacy: this.privacy
        });
    }
}
