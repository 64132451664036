import { Component, HostBinding } from '@angular/core';
import {trigger, stagger, animate, style, group, query as q, transition, keyframes} from '@angular/animations';
import { routerTransition} from './router.transition';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ routerTransition ],
})
export class AppComponent {

  getState(outlet:any) {
    //console.log(outlet.activatedRouteData.state)
    return outlet.activatedRouteData.state;
  }
}
