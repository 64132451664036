
<app-header></app-header>

<div class="row mt-4">
    <div class="col-12 mt-4">
        <a routerLink="/{{lang}}/camera" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class=" mt-4">
            <div class="row">
                <div class="col-2 offset-2" [@masoryTransition]>
                    <img class="img-responsive" src="./assets/cappello.svg" alt="Museo Logo" />
                </div>
                <div class="col-6 mt-2 fontlink" [@masoryTransition]>
                    scegli un <span class="red">cappello</span> 
                </div>
            </div>
        </a>
    </div>
    <div class="col-12 mt-4">
        <a routerLink="/{{lang}}/camera" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="">
            <div class="row">
                <div class="col-2 mt-4 offset-2" [@masoryTransition]>
                    <img class="img-responsive" src="./assets/donna.svg" alt="Museo Logo" />
                </div>
                <div class="col-6 mt-4 fontlink green" [@masoryTransition]>
                    <div class="mt-4">indossalo</div>
                </div>
            </div>
        </a>
    </div>
    <div class="col-12 mt-4">
        <a routerLink="/{{lang}}/camera" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="">
            <div class="row">
                <div class="col-2 offset-2" [@masoryTransition]>
                    <img class="img-responsive" src="./assets/foto.svg" alt="Museo Logo" />
                </div>
                <div class="col-6 mt-4 fontlink" [@masoryTransition]>
                    scatta una <span class="orange">foto</span> 
                </div>
            </div>
        </a>
    </div>
    <div class="col-12 mt-4">
        <a routerLink="/{{lang}}/camera" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="">
            <div class="row">
                <div class="col-2 offset-2 mt-4" [@masoryTransition]>
                    <img class="img-responsive mt-4" src="./assets/email.svg" alt="Museo Logo" />
                </div>
                <div class="col-6 mt-4 fontlink align-middle" [@masoryTransition]>
                    <span class="red">condividila</span> subito con chi vuoi
                </div>
            </div>
        </a>
    </div>
    <div class="col-12 mt-4">
        <a routerLink="/{{lang}}/camera" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="">
            <div class="row">
                <div class="col-2 offset-2 mt-4" [@masoryTransition]>
                    <img class="img-responsive mt-4" src="./assets/social.svg" alt="Museo Logo" />
                </div>
                <div class="col-6 mt-4 fontlink" [@masoryTransition]>
                    condividila sui <span class="green">social con l'hastag</span>
                </div>
            </div>
        </a>
    </div>
    <div class="col-12">
        <a routerLink="/{{lang}}/camera" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="">
            <div class="row">
                <div class="col-10 offset-2 mt-4 fontlink" [@masoryTransition]>
                    #museodelcappellomontappone
                </div>
            </div>
        </a>
    </div>
</div>

