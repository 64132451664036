import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {Observable,of, from } from 'rxjs';


@Injectable()
export class Globals {
    public base: string = 'https://totem.museodelcappellomontappone.it/';
    public baseServer: string = 'https://totem.museodelcappellomontappone.it/server/';
    //public base: string = 'http://localhost:4200/';
    //public baseServer: string = 'http://localhost:8880/';
    //ng b -prod -aot=false
    public lang:string
    public itinerari:any
    public itineraridetail:any
    public camera:any
    public poster:any
    public messaggio:any
    public mini: any
    public visitare: any
    public start: any
    public arte: any
    
    constructor(private ruta:ActivatedRoute, private router: Router) { 
        //console.log('parent '+this.router.parseUrl(this.router.url))
        let rout = this.router.parseUrl(this.router.url).toString()
        if(rout == '/'){
            this.lang = 'it';
        }else{
            let lang = this.router.parseUrl(this.router.url).root.children.primary.segments[0].toString();
            this.lang = lang;
        }
        
        if(this.lang != 'it' && this.lang != 'en'){
            window.location.replace(this.base+'it');
        }
        //TRADUZIONI
        if(this.lang == 'it'){
            //ITINERARI
            this.itinerari = {}
            this.itinerari['uno'] = 'In ogni mattone una storia'
            this.itinerari['unodesc'] = 'Da Piazza del Popolo alla Catedrale'
            this.itinerari['due'] = 'L\'Antica via delle botteghe: tra noviltà e fede'
            this.itinerari['duedesc'] = 'Da Piazza del Popolo alla chiesa di San Filippo'
            this.itinerari['tre'] = 'Palazzo dei Priori'
            this.itinerari['tredesc'] = 'Uno scrigno di storia e bellezza'
            this.itinerari['quattro'] = 'il colore nel centro storico'
            this.itinerari['quattrodesc'] = 'Un viaggio nel colore tra palazzi e opere d\'arte'
            //CAMERA
            this.camera = {}
            this.camera['uno'] = 'scatta ora la tua foto '
            this.camera['unodue'] = 'e spediscila a chi vuoi'
            this.camera['due'] = 'quando sei pronto '
            this.camera['duedue'] = ' tocca il pulsante qui sotto'
            this.camera['duetre'] = ' avrai 5 secondi di tempo per metterti in posa.'
            this.camera['duequattro'] = 'Potrai inviare la tua cartolina personalizzata a chi vuoi, insieme ad un messaggio di testo'
            this.camera['tre'] = 'SCATTA'
            this.camera['tredue'] = 'LA FOTO'
            this.camera['quattro'] = 'AVANTI'
            //POSTER
            this.poster = {}
            this.poster['uno'] = 'ora'
            this.poster['due'] = 'scegli los sfondo'
            this.poster['tre'] = 'che preferisci tra questi:'
            this.poster['quattro'] = 'AVANTI'
            //MESSAGGIO
            this.messaggio = {}
            this.messaggio['uno'] = 'Il nome è richiesto'
            this.messaggio['due'] = 'L\'email è richiesto'
            this.messaggio['tre'] = 'L\'indirizzo email deve contenere almeno il carattere @'
            this.messaggio['quattro'] = 'E-mail destinatario'
            this.messaggio['cinque'] = 'Oggetto del messaggio'
            this.messaggio['sei'] = 'Il campo è richiesto'
            this.messaggio['sette'] = 'Il tuo messaggio'
            this.messaggio['otto'] = 'Acconsento'
            this.messaggio['privacy'] = 'Accetta la policy privacy'
            this.messaggio['nove'] = 'INVIA LA CARTOLINA'
            //MINI HEAD
            this.mini = {}
            this.mini['uno'] = 'TORNA'
            this.mini['due'] = 'INDIETRO'
            //VISITARE
            this.visitare = {}
            this.visitare['uno'] = 'TORNA'
            this.visitare['due'] = 'INDIETRO'
            this.visitare['tre'] = 'luoghi'
            this.visitare['quattro'] = 'da visitare'
            this.visitare['cinque'] = 'seleziona la categoria'
            this.visitare['sei'] = 'e scopri gli imperdibili tesori del fermano'
            this.visitare['sette'] = 'Luoghi'
            this.visitare['otto'] = 'Musei'
            this.visitare['nove'] = 'Teatri'
            this.visitare['dieci'] = 'Palazzi'
            this.visitare['undici'] = 'Chiese'
            this.visitare['dodici'] = 'Opere d\'Arte'
            //START
            this.start = {}
            this.start['uno'] = 'ITINERARI'
            this.start['due'] = ' TEMATICI'
            this.start['tre'] = 'quattro'
            this.start['quattro'] = 'percorsi'
            this.start['cinque'] = 'alla'
            this.start['sei'] = 'scoperta'
            this.start['sette'] = 'della città'
            this.start['otto'] = 'LUOGHI'
            this.start['nove'] = ' DA VISITARE'
            this.start['dieci'] = 'scopri'
            this.start['undici'] = 'gli imperdibili'
            this.start['dodici'] = 'tesori'
            this.start['tredici'] = 'del fermano'
            this.start['quattordici'] = 'ZOOM'
            this.start['quindici'] = ' OPERE D\'ARTE'
            this.start['sedici'] = 'guarda'
            this.start['diciasette'] = 'le opere'
            this.start['diciotto'] = 'da vicino'
            this.start['dicianove'] = 'SCATTA'
            this.start['venti'] = 'ORA LA TUA FOTO E'
            this.start['ventuno'] = ' SPEDISCILA'
            this.start['ventidue'] = ' A CHI VUOI'
            //ITINERARIDETAIL
            this.itineraridetail = {}
            this.itineraridetail['uno'] = 'presentazione dell\'itinerario'
            this.itineraridetail['due'] = 'itinerario'
            this.itineraridetail['tre'] = 'tappa'
            
        }

        if(this.lang == 'en'){
            //ITINERARI
            this.itinerari = {}
            this.itinerari['uno'] = 'In each brick a story'
            this.itinerari['unodesc'] = 'From Piazza del Popolo to the Catedrale'
            this.itinerari['due'] = 'The ancient via delle botteghe: between novitty and faith'
            this.itinerari['duedesc'] = 'From Piazza del Popolo to the church of San Filippo'
            this.itinerari['tre'] = 'Priory palace'
            this.itinerari['tredesc'] = 'A casket of history and beauty'
            this.itinerari['quattro'] = 'the color in the historic center'
            this.itinerari['quattrodesc'] = 'A casket of history and beauty'
            //CAMERA
            this.camera = {}
            this.camera['uno'] = 'take your photo'
            this.camera['unodue'] = ' and send it to whoever you want'
            this.camera['due'] = 'when you are ready'
            this.camera['duedue'] = ' tap the button below'
            this.camera['duetre'] = ' you will have 5 seconds to pose.'
            this.camera['duequattro'] = 'You can send your personalized postcard to whoever you want, together with a text message'
            this.camera['tre'] = 'TAKE'
            this.camera['tredue'] = 'A PICTURE'
            this.camera['quattro'] = 'NEXT'
             //POSTER
             this.poster = {}
             this.poster['uno'] = 'Now'
             this.poster['due'] = 'choose the background'
             this.poster['tre'] = 'that you prefer among these:'
             this.poster['quattro'] = 'NEXT'
             //MESSAGGIO
            this.messaggio = {}
            this.messaggio['uno'] = 'your e-mail'
            this.messaggio['due'] = 'Email is required'
            this.messaggio['tre'] = 'The email address must contain at least the @ character'
            this.messaggio['quattro'] = 'recipient e-mail'
            this.messaggio['cinque'] = 'Post subject'
            this.messaggio['sei'] = 'the field is required'
            this.messaggio['sette'] = 'your message'
            this.messaggio['otto'] = 'I agree'
            this.messaggio['privacy'] = 'privacy'
            this.messaggio['nove'] = 'SEND THE POSTCARD'
            //MINI HEAD
            this.mini = {}
            this.mini['uno'] = 'COME'
            this.mini['due'] = 'BACK'
            //VISITARE
            this.visitare = {}
            this.visitare['uno'] = 'COME'
            this.visitare['due'] = 'BACK'
            this.visitare['tre'] = 'places'
            this.visitare['quattro'] = 'to visit'
            this.visitare['cinque'] = 'select the category'
            this.visitare['sei'] = 'and discover the unmissable treasures of the Fermano'
            this.visitare['sette'] = 'Places'
            this.visitare['otto'] = 'Museums'
            this.visitare['nove'] = 'Theaters'
            this.visitare['dieci'] = 'Palaces'
            this.visitare['undici'] = 'Churches'
            this.visitare['dodici'] = 'Artwork'
            //START
            this.start = {}
            this.start['uno'] = 'ROUTES'
            this.start['due'] = ' THEME'
            this.start['tre'] = 'four'
            this.start['quattro'] = 'paths'
            this.start['cinque'] = 'to the'
            this.start['sei'] = 'discovery'
            this.start['sette'] = 'the city'
            this.start['otto'] = 'PLACES'
            this.start['nove'] = ' TO VISIT'
            this.start['dieci'] = 'discover'
            this.start['undici'] = 'the unmissable'
            this.start['dodici'] = 'treasures'
            this.start['tredici'] = 'of the Fermano'
            this.start['quattordici'] = 'ZOOM'
            this.start['quindici'] = ' ARTWORK'
            this.start['sedici'] = 'look at'
            this.start['diciasette'] = 'the works'
            this.start['diciotto'] = 'closely'
            this.start['dicianove'] = 'TAKE'
            this.start['venti'] = 'YOUR PICTURE NOW AND'
            this.start['ventuno'] = ' SEND IT'
            this.start['ventidue'] = ' TO WHOEVER YOU WANT'
            //ITINERARIDETAIL
            this.itineraridetail = {}
            this.itineraridetail['uno'] = 'presentation of the itinerary'
            this.itineraridetail['due'] = 'itinerary'
            this.itineraridetail['tre'] = 'stage'
        
        }
            
       
    }

    
}