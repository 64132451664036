import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { RouterModule, Routes } from '@angular/router';
import {Globals} from './globals';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { AppComponent } from './app.component';
import { DashComponent } from './dash/dash.component';
import { ErroreComponent } from './errore/errore.component';
import { MessaggioComponent } from './messaggio/messaggio.component';
import { CameraComponent } from './camera/camera.component';
import { PosterComponent } from './poster/poster.component';
import { StartComponent } from './start/start.component';
import { AutoredirectComponent } from './autoredirect/autoredirect.component';
import { AutoLogoutService } from './auto-logout.service';
import { HeaderComponent } from './header/header.component';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const routesApp:Routes = [
  {path:':lang/camera' , component:CameraComponent, data: { state: 'camera'} },
  {path:':lang/poster' , component:PosterComponent, data: { state: 'poster'} },
  {path:':lang/messaggio' , component:MessaggioComponent, data: { state: 'messaggio'} },
  {path:':lang/errore' , component:ErroreComponent, data: { state: 'errore'} },
  {path:':lang' , component:StartComponent, pathMatch: 'full', data: { state: 'home'} },
  {path:'' , component:StartComponent, pathMatch: 'full', data: { state: 'home'} },
  {path:'**' , redirectTo:'errore' },
]

@NgModule({
  declarations: [
    AppComponent,
    DashComponent,
    ErroreComponent,
    MessaggioComponent,
    CameraComponent,
    PosterComponent,
    StartComponent,
    AutoredirectComponent,
    HeaderComponent
  ],
  imports: [
    RouterModule.forRoot(routesApp),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [Globals],
  bootstrap: [AppComponent]
})
export class AppModule { }
