<app-header></app-header>
<div class="row mt-4">
    <div class="col-md-12  mt-4" [@masoryTransition] >
        <div class="col-md-12 text-center">
            <img src="{{imagePeople}}" class="w100"/>
        </div>   
    </div>
</div>
<form class="col" [formGroup]="myform" (ngSubmit)="onSubmit(myform.value)" novalidate autocomplete="off">

    <div class="row mt-4">
        <div class="col-12 mt-4" [@masoryTransition] >
            <label>Nome e Cognome</label>
       
            <div style="text-align:center">
                <input  id="t1" formControlName="nome" name="nome"   required type="text" class="input form-control" />
            </div>
            <div class="text-danger" *ngIf="nome.errors && (nome.dirty || nome.touched)">
                <p *ngIf="nome.errors.required">{{messaggiolang.uno}}</p>
            </div>
        </div>

        <div class="col-12 mt-4" [@masoryTransition] >
            <label>Email</label>
       
            <div style="text-align:center">
                <input  id="t2" required  formControlName="email"   type="email" class="input form-control" name="email"  />
            </div>
            <div class="text-danger" *ngIf="email.errors && (email.dirty || email.touched)">
                <p *ngIf="email.errors.required">{{messaggiolang.due}}</p>
                <p *ngIf="email.errors.pattern">{{messaggiolang.tre}}</p>
            </div>
        </div>

        
        <div class="col-12 mt-4" [@masoryTransition] >
            <label>Scrivi un messaggio</label>
            <textarea id="t5"  formControlName="messaggio" name="messaggio"   required class="input form-control mv25"></textarea>
            <div class="text-danger" *ngIf="messaggio.errors && (messaggio.dirty || messaggio.touched)">
                <p *ngIf="messaggio.errors.required">{{messaggiolang.sette}}</p>
            </div>
        </div>

    </div>

    <div class="row mt-4">
        <div class="col-md-12  mt-4" [@masoryTransition] >
            <div class="simple-keyboard"></div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12  mt-4" [@masoryTransition] >
            <label>
                <input type="checkbox"  formControlName="privacy" name="privacy"   required class="form-control" style="display:inline;max-width:50px;">
                <span style="top: -20px;position: relative;"> Acconsento al trattamento dei miei dati personali ai sensi dell'art. 13D.Lgs. 196/2003</span>
            </label>
            <div class="text-danger" *ngIf="privacy.errors && (privacy.dirty || privacy.touched)">
                <p *ngIf="privacy.errors.required">{{messaggiolang.privacy}}</p>
            </div>
        </div>
    </div>

    <div class="row mt-4 mb-4">
        <div class="col-md-12 mb-4 mt-4 fonttext" [@masoryTransition] >
            Tutto ok? Ora premi il tasto 
            <button type="submit" #next (ngSubmit)="onSubmit(myform.value)" class="inviabtn">Invia</button>
        </div>
    </div>

</form>

<div class="modal fade top25" [ngClass]="status ? 'show setdialog' : ''" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{mesage}}</h5>
        <button type="button" class="close" data-dismiss="modal"  (click)="openDialog()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
            <div class="col-4 borderdx hv50">
                <img class="w100 mt-4 pt-4" src="./assets/logo_museo.svg" alt="Comune">
            </div>
            <div class="col-8 fonttext ww">
                {{message}}
            </div>
        </div>
      </div>
      <div class="modal-footer">
        <a routerLink="/{{lang}}/" type="button" class="btn btn-secondary">Chiudi</a>
      </div>
    </div>
  </div>
</div>