<app-header></app-header>

<div class="row mt-4">
    <div class="col-md-12  mt-4" [@masoryTransition] >
        <div class="col-md-12 text-center">
            
            <video #hardwareVideo></video>
            <img src="" #HTMLImageElement class="w100"/>
            <canvas #HTMLCanvasElement></canvas>
            <a (click)="trashFoto()" class="none trash" #trash><fa-icon [icon]="faTrash" class="px60 text-danger"></fa-icon></a>
            <div class="spinner-border loading none counter" #loading role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>   
    </div>
</div>
<div class="col-12 mt-4">
    <div class="row mt-4">    
        <div class="col-4" [@masoryTransition] >
            <a class="back" routerLink="/{{lang}}/">
                <img class="imgfoto" src="./assets/back.svg"/>
            </a>
        </div>
        <div class="col-4 text-center" [@masoryTransition] >
            <div #counter class="bold upper px120 yanone none counter fonttext">{{seconds}}</div>
            <a (click)="countdown()"  #scatta class="textblack">
                <div class="bgblack"><fa-icon [icon]="faCamera" class="px60"></fa-icon></div>
                scatta la foto
            </a>
        </div>
        <div class="col-4 text-right" [@masoryTransition] >
            <a (click)="nextStep()" #next class="next none">
                <img class="imgnext" src="./assets/next.svg"/>
            </a>
           
        </div>
    </div>
</div>

<div class="col-12 mt-4">
    <div class="row mt-4">    
        <div class="col-md-12 fonttext" [@masoryTransition] >
            Crea la tua cartolina e inviala a chi vuoi!<br/>
            Scatta una foto con i tuoi amici, inserisci l'e-mail del destinatario e un messaggio di saluto, infine invia la tua e-card!
        </div>
    </div>
</div>
